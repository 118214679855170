.custom-table .amplify-table__row {
    background-color: var(--amplify-colors-background-primary);
}

.custom-table .amplify-table__row {
    background-color: var(--amplify-colors-background-primary);
    --amplify-components-table-row-striped-background-color: var(--amplify-colors-blue-10);
    --amplify-components-table-row-hover-background-color: var(--amplify-colors-blue-20);
    --amplify-components-table-data-font-weight: var(--amplify-font-weights-semibold);
}

.custom-table .amplify-table__th {
    background-color: var(--amplify-colors-background-primary);
    color: var(--amplify-colors-blue-80);
    font-size: var(--amplify-font-sizes-large);
}

.custom-table .amplify-table__headers {
    color: var(--amplify-colors-yellow-80);
}

.wompi-button {
    --amplify-components-button-primary-background-color: #0045a6;
    margin: 20px;
}

.wompi-text {
    font-size: 10px;
    color: #fff;
}

.wompi-image {
    width: 75px;
    height: auto;
}
.App {
    max-width: 95%;
    text-align: center;
}

.App-header {
    background-image: url(./utils/image/10436.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.App-link {
    color: #61dafb;
}

.amplify-button[data-variation='primary'] {
    background: #0045a6;
}